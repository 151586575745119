<template>
  <div class="stores-details">
    <div class="stores-details-container" v-for="item in stores" :key="item.store_id" >
      <div v-bind:class = "highlight === item.store_id?'highlight':''" >
        <div>
          <img src="https://cdn.zando.co.za/cms/pin.png" alt="pin" />
        </div>
        <div class="stores-details-info" @click='$emit("customChange", {loc:item.positions,id:item.store_id})'>
          <p class="stores-details-title">{{ item.store_title }}</p>
          <p class="stores-details-location" v-html="item.store_address" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import "./StoresDetails.scss";
export default {
  name: "StoresDetails",
  props: ["store","highlight","stores"],
  mounted: function () {
  // console.log(this.stores,'store details')
  },


};
</script>
