<template>
  <div class="details">
    <DetailedInfo :details="details" :brands="brands" :saleprice="saleprice"/>
  </div>
</template>

<script>
import "./Details.scss";

import DetailedInfo from "../../molecules/DetailedInfo";
export default {
  name: "Details",
  components: { DetailedInfo },
  props:["details","brands","saleprice"],
  data() {
    return {

    }
  },
};
</script>
