<template>
  <div class="select-input">

    <select :v-model="name" :id="id" @change="handleSelect">
      <option
        :value="opt.value"
        v-for="opt in options"
        :key="opt"
        @change="handleSelect(opt.value)"
      >
        {{ opt.value }}
      </option>
    </select>
  </div>
</template>

<script>


import "./SelectInput.scss";
export default {
  name: "SelectInput",
  props: ["label", "name", "id", "for", "options", "handleSelect"],
  mounted() {
   // console.log(this.name, "name");
  },
};
</script>
