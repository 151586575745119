<template>
<div class="product-details">
  <Details v-if="details" :details="details" :brands="brands" :saleprice="saleprice" />
</div>
</template>

<script>
import "./ProductDetails.scss";
import Details from "../../components/organisms/Details";
import data from "../../appData";
import algoliasearch from 'algoliasearch';
export default {
  name: "ProductDetails",
  components: {
    Details
  },
  props: ["brand", "product"],

  data() {
    return {
      details: false,
      brands: false,
      saleprice: false,
    };
  },

  created() {

    
    this.brands = data.brandInfo.list;
    //get ean
    var uri = this.product.split("-");
    var ean = uri[uri.length - 1];

    const client = algoliasearch('ZIETC15FK5', 'f368650b8a7822fad1bda1f8be2ada53');
    const index = client.initIndex(this.database);
    console.log(ean);
    index.search(ean, {
      restrictSearchableAttributes: [
        'ean',
      ]
    }).then(({ hits }) => {
      this.details = hits[0];
      document.title = "Sunglass Hut® South Africa Online Store | " + this.details.brand + " | " + this.details.name ;
    });
  },
};
</script>
