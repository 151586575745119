<template>
  <div class="detailed-info-bigger-inner">
    <div class="detailed-info-bread">
      <a href="/">Home</a> / <a v-if="details.brand" :href="`../../products/${details.brand}/`">{{details.brand}}</a> / <span>{{details.name}}</span>
    </div>

    <div class="detailed-info">
      <List :details="details" class="detailed-info-slides">
        <template v-slot:items>
          <Splide :options="options">
            <SplideSlide>
              <img :src="`${imageBase}product_images/${details.ean}_030A.jpg`" :alt="details.name+'-030A'">
            </SplideSlide>
            <SplideSlide>
              <img :src="`${imageBase}product_images/${details.ean}_000A.jpg`" :alt="details.name+'-000A'">
            </SplideSlide>
            <SplideSlide>
              <img :src="`${imageBase}product_images/${details.ean}_180A.jpg`" :alt="details.name+'-180A'">
            </SplideSlide>
            <SplideSlide>
              <img :src="`${imageBase}product_images/${details.ean}_090A.jpg`" :alt="details.name+'-090A'">
            </SplideSlide>
            <SplideSlide>
              <img :src="`${imageBase}product_images/${details.ean}_150A.jpg`" :alt="details.name+'-_150A'">
            </SplideSlide>
          </Splide>
        </template>
      </List>
      <div class="detailed-info-info">
        <div class="detailed-info-brands">
          <h1 class="text-left text-2xl">{{ details.brand }} - {{ details.name }}</h1>
          <h2 class="text-left py-3">{{ details.short_desc }}</h2>
          <span class="text-sm">UPC: {{ details.ean }}</span>
      </div>
      <div class="detailed-info-details">
        <div>
          <p>{{ details.productId }}</p>
          <span class="detailed-info-details-price text-4xl font-semibold">R{{ details.saleprice ? details.saleprice : details.price }}.00</span>
        </div>
        <div class="sale-price" v-if="details.saleprice">R <span class="line-through">{{details.price}}</span></div>
      </div>
      <div class="detailed-info-buy">
        <div class="product-action-store">
          <a href="/store-locator">Find In Store</a>
        </div>
        <div class="product-action-zando" v-if="this.stock">
        <!--  <a v-if="this.zandoLink" :href="this.zandoLink" target="_blank">Buy now</a> -->
        </div>

      </div>
      <div class="detailed-info-specifics" @click="openSpecs">
        <h2 class="">Specifications</h2>
        <i class="fa fa-angle-right"></i>
      </div>
      <Modal class="detailed-info-modal" :class="{ showModal: specifications }" v-if="specifications" />
      <ProductSpecs class="detailed-info-modal-container" v-if="specifications" :details="details" :openSpecs="openSpecs" />
    </div>
  </div>
  <div class="brand-info">
    <div v-for="item in brands" :key="item.name">
      <div v-if="item.name === details.brand">
        <p class="justify-center"><img :src="`${imageBase}${item.img}`" :alt="item.name"></p>
        <p>{{ item.desc }}</p>
        <p><a :href="item.url">Shop All {{ item.name }}</a></p>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import "./DetailedInfo.scss";
import List from "../../atoms/List";
import Modal from "../../atoms/Modal";
import ProductSpecs from "../ProductSpecs";
//IMAGE SLIDER
import {
  Splide,
  SplideSlide
} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import stockDataJson from "../../../appData/stock.json";

export default {
  name: "DetailedInfo",
  components: {
    ProductSpecs,
    List,
    Modal,
    Splide,
    SplideSlide
  },
  props: ["details", "brands", "saleprice"],
  data() {
    return {
      stock: false,
      stockData: stockDataJson,
      zandoLink: false,
      slideIndex: 1,
      specifications: false,
      options: {
        rewind: true,
        gap: '1rem',
        arrows: false,
        type: 'loop',
        pagination: 'slider',

      },
    };
  },

  methods: {
    openSpecs() {
      this.specifications = !this.specifications;
    }
  },

  mounted() {

    window.addEventListener("scroll", this.showSlides);
    //build zando link and check stock
    var data = this.stockData;
    var link = data.filter(d => d.SKU_SUPPLIER_SOURCE == this.details.ean);
    //console.log(link[0].SK_PRODUCT, 'SK_PRODUCT');
    if (link.length == 1) {
      if (link[0].SOH >= 1) {

        var stor = JSON.parse(localStorage.getItem("items") || "[]");
        var utm_source = '';
        var utm_campaign = 'site';
        if (stor.utm_source !== undefined && stor.utm_source !== null) {
          utm_source = "_" + stor.utm_source;
        }
        if (stor.utm_campaign !== undefined && stor.utm_campaign !== null) {
          utm_campaign = stor.utm_campaign
        }

        this.stock = link[0].COD_CATALOG_CONFIG;
        this.utmcode = link[0].DSC_SKU;
        this.content = link[0].SK_PRODUCT;
        if(this.details.brand.toLowerCase() === 'tom ford') {
          this.zandoLink = "";
        }
        else {
          this.zandoLink = '//www.zando.co.za/' + this.details.brand.toLowerCase() + '-' + this.details.name.replace(/\s/g, "-").toLowerCase() + '-' + this.stock + '.html?utm_source=zando_sgh' + utm_source + '&utm_medium=link&utm_campaign=' + utm_campaign + '&utm_content=' + this.content;
        }
        //  console.log('//www.zando.co.za/' + this.details.brand.toLowerCase() + '-' + this.details.name.replace(/\s/g, "-").toLowerCase() + '-' + this.stock + '.html')
        // var utmname = localStorage.getItem("items");
        // localStorage.setItem("timestamp", JSON.stringify(new Date().getTime() - (1000 * 60 * 2)));
        // console.log(utmname, 'utm_campaign');
      }

    } else {
      //  this.content = link[0].SK_PRODUCT;
      // this.zandoLink = '//www.zando.co.za/' + this.details.brand.toLowerCase() + '-' + this.details.name.replace(/\s/g, "-").toLowerCase() + '-' + '.html?utm_source=zando_sgh&amp;utm_medium=link&utm_campaign=site&utm_content=' + this.content;
      //console.log('//www.zando.co.za/' + this.details.brand.toLowerCase() + '-' + this.details.name.replace(/\s/g, "-").toLowerCase() + '-' + '.html')
    }
  },
};
// COMMENT
</script>
