<template>
  <div class="perfect-pair">
    <TextComponent :heading="heading" :text="text" />
    <div class="perfect-pair-smaller-inner">
      <ContentAccordion
        v-for="item in list"
        :key="item"
        :item="item"
        :src="item.activeBackground"
      >
        <template v-slot:title />
        <template v-slot:content>
          <p class="m-3 text-sm text-left" v-html="item.text"></p>
        </template>
      </ContentAccordion>

    <div class="text-left">
      <h2 class="text-left m-3 text-lg">Additional terms applying to our Perfect Pair Promise</h2>
      <p class="text-left m-3 text-sm">Our Sunglass Hut - Perfect Pair Promise applies only to purchases of sunglass products by customers. It does not apply to commercial purchasers or resellers of sunglass products. Our Perfect Pair Promise does not apply to sunglasses sold as "imperfect" or "as-is merchandise". All claims are subject to the returned sunglass product being genuine and unaltered.</p>

      <p class="text-left m-3 text-sm">It does not apply in the case of lost or stolen sunglasses or where Proof of Purchase is not provided. All claims must be made within the stipulated timeframe from the date of purchase.</p>

      <p class="text-left m-3 text-sm">To make a claim the customer must return the sunglass product to any Sunglass Hut retail store in the country where the product has been purchased. Please note; it will be sent to the customer care centre as warranties are centralized.</p>

      <p class="text-left m-3 text-sm">If for any reason the customer cannot return the sunglass product to the original Sunglass Hut retail store, the customer should contact Sunglass Hut customer service at +27 86 100 0850.</p>

      <p class="text-left m-3 text-sm">Any refunds will be provided with the same method of the payment . The refund amount will be the amount of the original retail price paid by the customer as evidenced in the provided Proof of Purchase document. Where the original purchase was made by gift card, a replacement gift card will be provided.</p>

      <p class="text-left m-3 text-sm">The customer agrees to transfer ownership of the returned sunglass product to the Sunglass Hut retail store. The returned sunglass product will not be given back to the customer.</p>

      <p class="text-left m-3 text-sm">The Sunglass Hut retail store will assess the sunglasses to determine whether the claim falls within the terms of the Sunglass Hut - Perfect Pair Promise (30 days). It may be necessary for Sunglass Hut to return the sunglasses to the manufacturer to determine if the customer is entitled to a warranty claim. The Sunglass Hut retail store will in any case offer assistance for 24 months from the date of purchase, despite any shorter term of the manufacturer's warranty. The Sunglass Hut - Perfect Pair Promise does not exclude or otherwise limit the terms of any manufacturer warranty. Sunglass Hut reserves the right in its discretion to cancel or modify the terms of the Perfect Pair Promise at any time.</p>

      <h3 class="text-left m-3 text-lg">Application of customer laws</h3>
      <p class="text-left m-3 text-sm"> For customers who are covered by customer protection laws or regulations in their country of purchase or, if different, their country of residence, the benefits conferred by the Sunglass Hut - Perfect Pair Promise are in addition to all rights and remedies conveyed by such customer protection laws and regulations. The Sunglass Hut - Perfect Pair Promise does not exclude or otherwise limit any rights or remedies of the customer conveyed by such customer protection laws and regulations.</p>

      <h3 class="text-left m-3 text-lg"> Customer support</h3>
      <p class="text-left m-3 text-sm">For customer queries or support contact Sunglass Hut customer service at +27 86 100 0850 or warranty@za.sunglasshut.com</p>
    </div>
  </div>
  </div>
</template>

<script>
import "./PerfectPair.scss";
import data from "../../appData/index";
import TextComponent from "../../components/molecules/TextComponent";
import ContentAccordion from "../../components/molecules/ContentAccordion";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";

export default {
  name: "PerfectPair",
  components: { TextComponent, ContentAccordion },

  data() {
    return {
      heading: "we offer more than just a great pair of shades.",
      text: [
        "<p>We want your new pair of shades to be perfect.</p>",
        "<p>That’s why Sunglass Hut offers:</p>",
      ],
      list: data.perfectPair.content,
      isOpen: false,
    };
  },
  created () {
    
  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Perfect Pair Promise",
      description:
        "we offer more than just a great pair of shades. We want your new pair of shades to be perfect.",
    });
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
