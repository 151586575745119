<template>
  <div class="locator-bigger-inner">
    <div class="locator">
      <div class="m-5">
        <h1>Store Locator</h1>
      </div>
      <div>
        <GoogleMap />
      </div>
    </div>
  </div>
</template>

<script>
import "./Locator.scss";
import GoogleMap from "../../molecules/GoogleMap";
export default {
  name: "Locator",
  components: { GoogleMap },
  props: [],
};
</script>
