<template>
  <div class="content-accordion">
    <button @click="toggleAccordion()" class="content-accordion-button" :style="[
      isOpen
        ? { backgroundImage: `url(${src})` }
        : { backgroundColor: '#c3c4c5' },
    ]">
      <div class="content-accordion-signs" :class="{ openContent: isOpen }">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path v-if="isOpen"
            d="M21.9267 13.3017L21.5732 12.9482L16 18.5215L10.4267 12.9482L10.0732 13.3017L16 19.2285L21.9267 13.3017Z"
            fill="#222222" />
          <path v-else
            d="M13.4267 21.9267L13.0732 21.5732L18.6465 16L13.0732 10.4267L13.4267 10.0732L19.3535 16L13.4267 21.9267Z"
            fill="#222222" />
        </svg>
      </div>
      <div class="content-accordion-button-container">
        <div class="content-accordion-button-imgs">
          <FocalImage v-if="isOpen" :src="item.activeIcon" />
          <FocalImage v-else :src="item.inactiveIcon" />
        </div>
        <div class="content-accordion-button-content" :class="{ openContent: isOpen }">
          <h4>{{ item.heading }}</h4>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </button>
    <div v-if="isOpen" class="content-accordion-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import "./ContentAccordion.scss";
import FocalImage from "../../atoms/FocalImage";
export default {
  name: "ContentAccordion",
  components: { FocalImage },
  data() {
    return {
      isOpen: false,
      active: false,
    };
  },
  props: ["src", "item"],
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
