<template>
  <div class="store-locator">
    <Locator />
  </div>
</template>

<script>
import "./StoreLocator.scss";
import Locator from "../../components/organisms/Locator";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";
export default {
  name: "StoreLocator",
  components: { Locator },
  props: [],
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Store Locator",
      description:
        "Sunglass Hut® South Africa Online Store | Store Locator",
    });
  },
};
</script>
