<template>
  <div class="privacy-policy-page">
    <div v-for="item in text" :key="item">
      <TextComponent :heading="item.header" :text="item.text" />
    </div>
    <br/>
    <br/>
    <div id="section-paia" v-for="item in documenttitle" :key="item">
      <h4 class="text-xl font-bold mb-2 text-left">{{item.header}}</h4>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-12">
      <div v-for="item in document" :key="item" class="flex flex-col items-start text-black border-2 border-gray-300 rounded-md px-6 pt-6 pb-16 bg-gray-200 relative">
        <h4 class="text-xl font-bold mb-4">{{item.header}}</h4>
        <p class="text-lg">{{item.text}}</p>
        <a :href="item.link" target="_blank" class="absolute h-full w-full"></a>
      </div>
    </div>
  </div>
</template>

<script>
import "./PrivacyPolicy.scss";
import TextComponent from "../../components/molecules/TextComponent";
import data from "../../appData/index";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";

export default {
  name: "PrivacyPolicy",
  components: { TextComponent },

  mounted () {
    let targetValue = this.$route.query.target;

    if(targetValue == "section-paia"){
      setTimeout(() => {
          this.scrollToElement();

          const offsetTop = document.getElementById("section-paia").offsetTop - 170;

          window.scrollTo({
            top: offsetTop,
          });
      }, 500);
    }
  },

  data() {
    return {
      text: data.privacyPolicy.content,
      documenttitle: data.privacyPolicy.documentTitle,
      document: data.privacyPolicy.document,
    };
  },

  created () {
  },

  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Privacy Policy",
      description:
        "Sunglass Hut® South Africa Online Store | Privacy Policy",
    });
  },

  methods: {
    scrollToElement() {
      var imageElement = document.getElementById("section-paia");
      imageElement.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
