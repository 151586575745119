<template>
  <div class="product-specs">
    <div class="product-specs-content">
      <div class="product-specs-header">
        <i class="font-bold" @click="openSpecs" >x</i>
        <h4>Product Details</h4>
      </div>
      <div class="product-specs-body">
        <div v-if="details.description" class="product-specs-body-info">
          <h4>Description</h4>
          <p>{{ details.description }}</p>
        </div>
        <div class="product-specs-body-info">
          <h4>specs</h4>
          <div v-if="details.frame_color"  class="product-specs-body-specs">
            <p>EAN:</p>
            <p>{{ details.ean }}</p>
          </div>
          <div v-if="details.frame_color"  class="product-specs-body-specs">
            <p>Frame color:</p>
            <p>{{ details.frame_color }}</p>
          </div>
          <div v-if="details.frame_material" class="product-specs-body-specs">
            <p>Frame material:</p>
            <p>{{ details.frame_material }}</p>
          </div>
          <div v-if="details.lens_material" class="product-specs-body-specs">
            <p>lens material:</p>
            <p>{{ details.lens_material }}</p>
          </div>
          <div v-if="details.brand " class="product-specs-body-specs">
            <p>brand:</p>
            <p>{{ details.brand }}</p>
          </div>
          <div v-if="details.gender" class="product-specs-body-specs">
            <p>gender:</p>
            <p>{{ details.gender }}</p>
          </div>
          <div v-if="details.shape " class="product-specs-body-specs">
            <p>shape:</p>
            <p>{{ details.shape }}</p>
          </div>
          <div v-if="details.lens_color " class="product-specs-body-specs">
            <p>lens color:</p>
            <p>{{ details.lens_color }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./ProductSpecs.scss";
export default {
  name: "ProductSpecs",
  props: ["details", "openSpecs"],
};
</script>
