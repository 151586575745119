<template>
  <div class="google-map">

    <StoreSearch
      :stores="stores"
      :location="location"
      :cities="cities"
      :selectedTab="selectedTab"
      :handleChange="handleSearchChange"
      :handleSelect="handleSelect"
    />
    <GMapAutocomplete
        selectFirstOnEnter="true"
        placeholder="Search Town or City"
        @place_changed="setPlace"
        class="auto-comp"
        :options="{
            componentRestrictions: {country: 'za'},
            strictBounds: true
       }"
    >
    </GMapAutocomplete>
    <div class="google-map-stores">
      <StoresDetails :store="filteredSelect" :stores="this.storesLocations" @custom-change="changeLocation" :highlight=openedMarkerID />
      <GMapMap
          ref="myMapRef"
        :options="{
          zoomControl: true,
        }"
        :center=center
        :zoom=zoom
        map-type-id="terrain"
      >
        <GMapMarker
            :key="index"
            v-for="(m, index) in stores"
            :position="m.positions"
            :clickable="true"
            :draggable="false"
            @click="openMarker(m.store_id)"
            @closeclick="openMarker(null)"
        >
          <GMapInfoWindow
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.store_id"
          >
            <h2 v-html="m.store_title"></h2>
            <div v-html="m.store_address"></div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>
  </div>
</template>

<script>

import "./GoogleMap.scss";
import data from "../../../appData/storeLocator";
import pin from "../../../assets/pin.png";
import StoreSearch from "@/components/molecules/StoreSearch";
import StoresDetails from "@/components/molecules/StoresDetails";
export default {
  name: "GoogleMap",
  components: { StoresDetails, StoreSearch },
  props: [],
  data() {
    return {
      icon: pin,
      storesLocations:data.markers,
      stores: data.markers,
      center: { lat: -26.3654169696902, lng: 	24.504919262695303},
      zoom:5,
      location: "",
      selectedTab: "",
      openedMarkerID: null,
      cities: [
        { value: "All", location:{ lat: -26.3654169696902, lng: 	24.504919262695303}, zoom:5 },
        { value: "Cape Town", location:{ lat: -33.918861, lng: 	18.423300}, zoom:12 },
        { value: "Bloemfontein" , location:{ lat: -29.118114745419, lng: 26.208747369287487 }, zoom:12 },
        { value: "Durban" , location:{ lat: -29.883333, lng: 31.049999 }, zoom:12 },
        { value: "East London" , location:{ lat: -33.0343, lng: 27.8193 }, zoom:12 },
        { value: "Johannesburg" , location:{ lat: -26.195246, lng: 28.034088}, zoom:12 },
        { value: "Kimberley" , location:{ lat: -28.741943, lng: 24.771944}, zoom:12 },
        { value: "Nelspruit (Mpumalanga)" , location:{ lat: -25.4745, lng: 30.9703 }, zoom:12 },
        { value: "North West" , location:{ lat: -26.711997, lng: 27.103951 }, zoom:12 },
        { value: "Polokwane (Limpopo)" , location:{ lat: -23.9045, lng: 29.4689 }, zoom:12 },
        { value: "Port Elizabeth" , location:{ lat: -33.9608, lng: 25.6022 }, zoom:12 },
        { value: "Pretoria" , location:{ lat: -25.731340, lng: 28.218370}, zoom:12 },
        { value: "Rustenburg" , location:{ lat: -25.7074663, lng: 27.2520755 }, zoom:12 },
        { value: "Welkom" , location:{ lat: -27.976779, lng: 26.7360168}, zoom:12 },
        { value: "Windhoek" , location:{ lat: -22.5594 , lng: 17.0832 }, zoom:12 },
        { value: "Witbank" , location:{ lat: -25.8871387, lng: 29.2637958 }, zoom:12 },

      ],
    };
  },
  computed: {
    filteredList() {
      return this.stores.filter((i) => {
        return i.store_address
          .toLowerCase()
          .includes(this.location.toLowerCase());
      });
    },
    filteredSelect()
    {
      return this.filteredList.filter((i) => {
        return i.store_city
          .toUpperCase()
          .includes(this.selectedTab.toUpperCase());
      });
    },
  },
  mounted: function () {
    //this.changeLocation('Cape Town')

    this.$refs.myMapRef.$mapPromise.then((map) => {
      map.addListener('click', (mapsMouseEvent) => {
        console.log(mapsMouseEvent.latLng.lat());
        console.log(mapsMouseEvent.latLng.lng());
      });
    });

    this.$refs.myMapRef.$mapPromise.then((map) => {

      map.addListener('change', (mapsMouseEvent) => {
       // console.log(mapsMouseEvent,'change event');
        this.find_closest_marker(mapsMouseEvent.geometry.location.toJSON());

      });
    });
  },
  methods: {
    setPlace(event)
    {
      this.center = event.geometry.location.toJSON();
      this.zoom = 14;
      this.selectedTab = event.address_components[1].long_name;
      //console.log(event,'place');
     // console.log(event.geometry.location.toJSON(),'lats');


      this.find_closest_marker(event.geometry.location.toJSON());
    },
    handleSearchChange(e) {

      this.handleSelect(e);
      //console.log(this.location, "location");
    },
     rad(x) {return x*Math.PI/180;},

 find_closest_marker( event ) {
      var store = this.stores;
  var lat = event.lat;
  var lng = event.lng;
  var R = 6371; // radius of earth in km
  var distances = [];
  var local = [];
  var closest = -1;
  var i=0;
  for( i=0;i< store.length; i++ ) {
    var mlat = store[i].positions.lat;
    var mlng = store[i].positions.lng;
    var dLat  = this.rad(mlat - lat);
    var dLong = this.rad(mlng - lng);
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.rad(lat)) * Math.cos(this.rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    distances[i] = d;
    if ( closest == -1 || d < distances[closest] ) {
      closest = i;
    }
    var distance = 100;
    console.log(this.selectedTab,'store');
    if(this.selectedTab === "All")
    {
      distance = 10000;
    }

    if(distances[i] <= distance)
    {
      store[i].distance = distances[i];
      local.push(store[i]);

    }
  }
//this.filteredSelect = local;

   local.sort((a, b) => a.distance - b.distance)

  //console.log(local,'close stores');


  this.storesLocations = local;


},
    handleSelect(tab) {




      this.selectedTab = tab.target.value;
      //console.log(this.selectedTab, "opt");
     // console.log(this.selectedTab, "Full Opt");
/*
      var result = this.cities.find(obj => {
        return obj.value === this.selectedTab
      })*/

      //var result = this.cities.filter(x => x.value === this.selectedTab).map(x => x.location);

     // console.log(result,'location');

      var item = this.cities.find(item => item.value === tab.target.value);

// print
     // console.log(item.location);
      this.find_closest_marker(item.location);

      this.center = item.location;
      this.zoom = item.zoom;
      this.openMarker(item.store_id);

    },
    openMarker(id) {
      this.openedMarkerID = id;

    },

    changeLocation(value) {

      this.center = value.loc;
      this.zoom = 18;
      setTimeout(function(){
        this.openMarker(value.id);

       // console.log(value.id,'toggle');
      }.bind(this), 500);
      this.openMarker(value.id);


    }
  },
};
</script>
