<template>
  <div id="dbl-click" class="store-search">
    <SelectInput
      :name="selectedTab"
      type="select"
      label="select city:"
      :options="cities"
      :handleSelect="handleSelect"
    />

  </div>
</template>

<script>


import "./StoreSearch.scss";
import SelectInput from "@/components/atoms/SelectInput";

export default {
  name: "StoreSearch",
  components: { SelectInput },
  props: [
    "stores",
    "location",
    "handleChange",
    "cities",
    "city",
    "handleSelect",
    "selectedTab",
  ],
  data() {
    return {
      icon: false,
      button: true,
    };
  },
};
</script>
